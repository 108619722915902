<template>
    <div class="card mt-5">
        <div class="card-body card-custom-spacing-tblf-10 bg-white">
            <div class="container-fluid p-0">
                <div class="row p-0">
                    <div class="col-12">
                        <vc-tables 
                            is-sr
                            :headers="users"
                            :data="datas"
                            :show-actions="false"
                            is-edit-action
                            :srs="srs"
                        />
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-4">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                <div class="dataTables_length">
                                    <el-select
                                        v-model="userP.per_page"
                                        class="perPages"
                                        @change="userP.page = 1"
                                    >
                                        <el-option v-for="page in per_pages" :key="page" :label="page" :value="page" />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-center">
                            <span class="text-muted d-block mt-2">
                                Showing {{ footerData.from }} to {{ footerData.to }} of {{ footerData.total }}
                            </span>
                        </div>
                        <div class="col-4">
                            <div style="float: right">
                                <el-pagination
                                    v-model:current-page="userP.page"
                                    :page-size="userP.per_page" 
                                    :small="false"
                                    layout="prev, pager, next"
                                    :total="footerData.total"
                                    @current-change="changePagination"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { per_pages } from '@/core/utils/Listing'
import { users } from '@/core/utils/Tables.ts'
import { userP } from '@/store/composable/Listing.js'

defineProps({
    datas: {
        type: Function,
        required: true
    },
    footerData: {
        type: Object,
        required: true
    }
    
})

function changePagination(e) {
    userP.value.page = e;
}

const srs = computed(() => {
    return (userP.value.page - 1) * userP.value.per_page
})
</script>