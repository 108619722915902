<template>
    <div class="card card-user-listing container container-lead bg-white border-radius-4">
        <div class="card-header border-0 p-0 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center position search-block">
                <div class="search-field-block">
                    <el-input
                        placeholder="Search User"
                        v-model="userSearch"
                        @keydown.enter.prevent="keywordPress"
                        ref="sfilter"
                    >
                        <template #prefix>
                            <span class="svg-icon svg-icon-1 me-12">
                                <inline-svg
                                    src="/media/icons/duotune/general/gen021.svg"
                                />
                            </span>
                        </template>
                    </el-input>
                </div>

                <div class="bd-highlight search-buttons-block">
                    <button 
                        type="button"
                        class="btn btn-light-info rounded-0 cursor-pointer"
                        @click="keywordPress"
                    >
                        Search
                    </button>
                    <button 
                        type="button"
                        style="border-radius: 0"
                        class="btn btn-light-info"
                        @click="setUserP"
                    >
                        Reset
                    </button>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-end">
                <div class="d-flex align-items-center position-relative me-2">
                    <el-select
                        v-model="userP.role_id"
                        @change="userP.page = 1"
                    >
                        <el-option :value="0" label="Select Role" />
                        <el-option v-for="role in roles" :key="role.value" :label="role.label" :value="role.value" />
                    </el-select>
                </div>
                <div class="d-flex align-items-center position-relative me-2">
                    <el-select
                        v-model="userP.status"
                        @change="userP.page = 1"
                    >
                        <el-option v-for="status in userStatuses" :key="status.value" :label="status.label" :value="status.value" />
                    </el-select>
                </div>
                <div class="card-toolbar m-0">
                    <div class="d-flex justify-content-end">
                        <div class="bd-highlight">
                            <button 
                            type="button"
                            class="btn btn-info rounded-0"
                            @click="redirectPage"
                            >
                                Add User
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { userP, userSearch, setUserP } from '@/store/composable/Listing.js'
import { userStatuses } from '@/core/utils/Listing.ts'
import { useRouter } from 'vue-router'

const props = defineProps({
    roles: {
        type: Function,
        required: true
    }
})

const router = useRouter()

function keywordPress() {
    userP.value.keyword = userSearch.value
}


function redirectPage() {
    const routerData = router.resolve({ name: 'user-create' })
    window.open(routerData.href, '_blank')
}
</script>